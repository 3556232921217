<script setup>

</script>

<template>
<!--<a class="whatsapp-link" href="https://chat.whatsapp.com/CZRWIaaunhC1eGNWrlDavi" target="_blank">
    <button id="vAskWhatsapp" class="material-button d-flex align-items-center">
      <span class="button-text">هل لديك سؤال؟</span>
      <span class="button-icon">
          <svg style="border-radius: 50%" viewBox="0 0 360 360" height="24" width="24" preserveAspectRatio="xMidYMid meet"
               class="xh8yej3 x5yr21d x14yjl9h xudhj91 x18nykt9 xww2gxu" version="1.1" id="Layer_1" x="0px" y="0px"
               enable-background="new 0 0 360 360"><title>wa-chat-psa</title><path fill="#25D366" opacity="1" stroke="none"
                                                                                   d=" M173.000000,361.000000  C115.353172,361.000000 58.206341,361.000000 1.029757,361.000000  C1.029757,241.061493 1.029757,121.122963 1.029757,1.092220  C120.896820,1.092220 240.793732,1.092220 360.845337,1.092220  C360.845337,120.999847 360.845337,240.999893 360.845337,361.000000  C298.456329,361.000000 235.978165,361.000000 173.000000,361.000000  M197.063446,92.379547  C195.411438,92.174431 193.762665,91.937508 192.106949,91.768898  C156.937073,88.187309 128.342728,100.457565 108.074654,129.217316  C87.867386,157.890823 86.575523,188.910645 101.408730,220.531891  C102.965797,223.851242 103.232262,226.553955 102.266563,229.950317  C98.463707,243.324921 94.899605,256.767395 91.084465,270.781311  C93.123474,270.351715 94.548683,270.112305 95.941422,269.748505  C108.650543,266.428864 121.320129,262.946716 134.088593,259.875153  C136.551529,259.282684 139.727280,259.569855 142.010056,260.646881  C157.967712,268.175995 174.705978,270.917206 192.102615,268.776276  C234.755585,263.527069 268.624664,226.903122 270.011841,184.898880  C271.516479,139.337418 241.558502,101.014793 197.063446,92.379547  z"></path><path
              fill="#FCFEFD" opacity="1" stroke="none"
              d=" M197.474182,92.506378  C241.558502,101.014793 271.516479,139.337418 270.011841,184.898880  C268.624664,226.903122 234.755585,263.527069 192.102615,268.776276  C174.705978,270.917206 157.967712,268.175995 142.010056,260.646881  C139.727280,259.569855 136.551529,259.282684 134.088593,259.875153  C121.320129,262.946716 108.650543,266.428864 95.941422,269.748505  C94.548683,270.112305 93.123474,270.351715 91.084465,270.781311  C94.899605,256.767395 98.463707,243.324921 102.266563,229.950317  C103.232262,226.553955 102.965797,223.851242 101.408730,220.531891  C86.575523,188.910645 87.867386,157.890823 108.074654,129.217316  C128.342728,100.457565 156.937073,88.187309 192.106949,91.768898  C193.762665,91.937508 195.411438,92.174431 197.474182,92.506378  M253.181854,196.536774  C255.711929,186.169617 256.004150,175.664429 253.541946,165.353546  C246.119568,134.271210 226.847565,114.125397 195.518936,107.758392  C164.775314,101.510277 138.969528,111.918282 120.761078,137.417480  C102.516304,162.967529 102.122711,190.360199 117.175308,217.804871  C119.110695,221.333557 119.630486,224.226303 118.452988,227.999252  C116.305588,234.880005 114.631348,241.908432 112.534187,249.714706  C121.244263,247.449783 128.886383,245.269287 136.634216,243.571182  C138.823502,243.091385 141.697876,243.342087 143.623520,244.406097  C187.851929,268.844574 240.426270,246.301453 253.181854,196.536774  z"></path><path
              fill="#26D367" opacity="1" stroke="none"
              d=" M253.113434,196.950668  C240.426270,246.301453 187.851929,268.844574 143.623520,244.406097  C141.697876,243.342087 138.823502,243.091385 136.634216,243.571182  C128.886383,245.269287 121.244263,247.449783 112.534187,249.714706  C114.631348,241.908432 116.305588,234.880005 118.452988,227.999252  C119.630486,224.226303 119.110695,221.333557 117.175308,217.804871  C102.122711,190.360199 102.516304,162.967529 120.761078,137.417480  C138.969528,111.918282 164.775314,101.510277 195.518936,107.758392  C226.847565,114.125397 246.119568,134.271210 253.541946,165.353546  C256.004150,175.664429 255.711929,186.169617 253.113434,196.950668  M159.161896,144.300629  C157.596588,142.566147 156.289551,139.760483 154.415009,139.308472  C149.758347,138.185654 145.465286,139.125748 141.936844,143.335083  C135.938049,150.491486 133.889008,158.646042 136.794312,167.095337  C139.180939,174.036118 142.985977,180.714874 147.247849,186.742218  C159.314850,203.807953 175.066055,216.142273 195.540466,221.815857  C205.004898,224.438538 213.684128,223.322174 221.218109,216.594635  C225.301636,212.948212 226.861237,207.950836 225.754288,203.034790  C225.255310,200.818695 221.393539,199.207153 218.857986,197.645233  C216.319427,196.081421 213.584717,194.800613 210.844025,193.606903  C202.134613,189.813538 203.863739,189.749985 198.366028,196.614182  C197.431046,197.781570 196.301865,198.814072 195.495682,200.059845  C193.532318,203.093781 191.249756,202.866211 188.234161,201.544495  C177.255371,196.732590 168.560989,189.383530 161.641464,179.587280  C158.640030,175.338043 158.010895,172.193756 162.204178,168.482956  C166.102249,165.033386 166.959518,160.921555 164.134537,156.022842  C162.157837,152.595093 161.014969,148.686508 159.161896,144.300629  z"></path><path
              fill="#FCFEFD" opacity="1" stroke="none"
              d=" M159.330811,144.646652  C161.014969,148.686508 162.157837,152.595093 164.134537,156.022842  C166.959518,160.921555 166.102249,165.033386 162.204178,168.482956  C158.010895,172.193756 158.640030,175.338043 161.641464,179.587280  C168.560989,189.383530 177.255371,196.732590 188.234161,201.544495  C191.249756,202.866211 193.532318,203.093781 195.495682,200.059845  C196.301865,198.814072 197.431046,197.781570 198.366028,196.614182  C203.863739,189.749985 202.134613,189.813538 210.844025,193.606903  C213.584717,194.800613 216.319427,196.081421 218.857986,197.645233  C221.393539,199.207153 225.255310,200.818695 225.754288,203.034790  C226.861237,207.950836 225.301636,212.948212 221.218109,216.594635  C213.684128,223.322174 205.004898,224.438538 195.540466,221.815857  C175.066055,216.142273 159.314850,203.807953 147.247849,186.742218  C142.985977,180.714874 139.180939,174.036118 136.794312,167.095337  C133.889008,158.646042 135.938049,150.491486 141.936844,143.335083  C145.465286,139.125748 149.758347,138.185654 154.415009,139.308472  C156.289551,139.760483 157.596588,142.566147 159.330811,144.646652  z"></path></svg>
        </span>
    </button>
  </a>-->
<div></div>
</template>

<style scoped>
.material-button {
  display: inline-block;
  padding: 6px 10px;
  border: none;
  border-radius: 4rem;
  background-color: white; /* Blue color */
  color: #2196F3;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.material-button:hover {
  background-color: lightgray; /* Darker shade of blue on hover */
}

.material-button .button-icon {
  margin-right: 8px;
}

.material-button .button-icon img {
  vertical-align: middle;
}

.material-button .button-text {
  vertical-align: middle;
}

.whatsapp-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  cursor: pointer; /* Change cursor to pointer */
}
</style>