<template>
  <div class="container-fluid pt-3 pb-3">
    <div class="row align-items-center">

      <div class="col-sm">
        <a href="https://www.mediafire.com/file/een6kf727b1p0uy/3.apk/file"><img
            src="https://seeklogo.com/images/D/download-android-apk-badge-logo-D074C6882B-seeklogo.com.png" width="150"
            height="50"></a>
      </div>

      <div class="col-sm text-right">
        <div id="title">
          <h2>مستقبلي</h2>
        </div>
        <h1 style="color:#000000;font-family:'Aldhabi';  text-align: right;">&#1583;&#1604;&#1610;&#1604;
          &#1605;&#1572;&#1587;&#1587;&#1575;&#1578; &#1575;&#1604;&#1578;&#1603;&#1608;&#1610;&#1606;
          &#1576;&#1593;&#1583; &#1575;&#1604;&#1576;&#1603;&#1575;&#1604;&#1608;&#1585;&#1610;&#1575;</h1>
      </div>

    </div>

    <nav dir="rtl" class="row navbar navbar-default navbar-inverse navbar-expand-sm">
      <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" style="background-color: white">
          <span class="navbar-toggler-icon" style="color: white"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <router-link class="r nav-link" to="/">
                <a :class="'btn ' + ((this.$route.path === '/') ? 'btn-light' : 'text-white')" type="button">
                  تكوينات
                </a>

              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="r nav-link" to="/concours">
                <a :class="'btn ' + ((this.$route.path === '/concours') ? 'btn-light' : 'text-white')" type="button">
                  مباريات
                </a>

              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="r nav-link" to="/notice">
                <a :class="'btn ' + ((this.$route.path === '/notice') ? 'btn-light' : 'text-white')" type="button">
                  تنويه
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="r nav-link" to="/qa">
                <a :class="'btn ' + ((this.$route.path === '/qa') ? 'btn-light' : 'text-white')" type="button">
                  أسئلة وأجوبة
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="r nav-link" to="/about">
                <a :class="'btn ' + ((this.$route.path === '/about') ? 'btn-light' : 'text-white')" type="button">
                  حول الموقع
                </a>
              </router-link>
            </li>
          </ul>
          <OpenWhatsapp class="me-auto"/>

        </div>
      </div>
    </nav>
  </div>


  <router-view />
</template>
<script>
import OpenWhatsapp from "@/components/OpenWhatsapp.vue";

export default {
  components: {OpenWhatsapp},
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      let collapse = document.getElementById('navbarTogglerDemo01');
      if (collapse) {
        collapse.classList.remove('show');
      }
    }
  }
}


</script>
<style>
@font-face {
  font-family: "Aldhabi";
  src: local("Aldhabi"),
    url(./assets/fonts/aldhabi.ttf) format("truetype");
}

.almarai-bold {
  font-family: "Almarai", sans-serif;
  font-weight: 700;
  font-style: normal;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  overflow-y: scroll;
}

nav {
  background-color: #1DA1F2;

}

nav a {
  font-weight: bold;
  color: #ffffff;
  font-family: Cairo,serif;
}

nav ul {
  padding-right: 0;
}

nav a:hover {
  color: #bebebe;
}

@media all and (max-width: 576px) {
  .navbar-collapse {
    position: absolute;
    padding: 10px 10px 10px 0;
    z-index: 1;
    top: 64px;
    width: 100%;
    right: 0;
    overflow-x: hidden !important;
    background-color: #1DA1F2;
  }
}

#title {
  text-align: right;
  color: #1DA1F2;
}
</style>
